<template>
  <div v-if="this.agreements">
    <div class="container" v-show="filter.flag">
      <SelectedFilters v-if="selectFilters.length !== 0" :data="selectFilters" @sendChange="filterChange = $event"/>
      <Search :isActiveFilter="btnFilter" @inputchange="search" @filter="filter.flag = false" v-if="agreements !== null" />
      <p class="list-not" v-if="listNot">{{$root.langText.notFound}}</p>
      <ul class="block-list">
        <router-link 
          tag="li"
          :key="index"
          :to = "{name: 'AgreementsCreateId', params: {id: agreement.id}}" 
          v-for="(agreement, index) in agreementsNew"
          :data-id="agreement.id"
          
        >
          <!-- demo -->
          <a style="display: block" v-if="$root.app !== 'SoftProCrmAppRioTransTest' && $root.app !== 'SoftProCrmAppRioTrans'">
            <i :style="{background: agreement.statusColorBG}"></i>
            <h4>#{{agreement.order_number}} 
              <p>{{$root.langText.from}} {{agreement.created_at | date}}</p></h4>
              <p>{{agreement.client_name ? agreement.client_name : '-'}}</p>
            <strong :style="{color: agreement.statusColorText}">{{agreement.status}}</strong>
            <div class="icon icon-arrow-right"></div>
          </a>
          

          <!-- riotrans -->
          <a style="display: block" v-else>
            <s v-if="agreement.item_viewed !== null" :style="{background: agreement.statusColorBG}"></s>
            <i :style="{background: agreement.statusColorBG}"></i>
            <div style="position: relative;z-index:2">
              <h4 style="display:flex;justify-content: space-between;">
                <strong :style="{color: agreement.item_viewed !== null ? '#004D9D' : agreement.statusColorText, 'text-transform': 'none'}">{{agreement.status}}</strong>
                <p style="font-weight:600">№{{agreement.order_number}}</p>
              </h4>
              <p style="font-weight:500;margin-bottom: 15px">{{agreement.client_name ? agreement.client_name : '-'}}</p>
              
              <div
                style="font-weight:500;line-height:0.9"
                v-for="(item, index) in agreement.orders_directions"
                :key="index"
              >
                <!-- <p v-if="item.poo_name && item.fd_name" style="display:flex"> -->
                <p style="display:flex;margin-bottom:8px">
                  {{item.poo_name}} - {{item.fd_name}}
                  <span 
                    style="margin: 1px 0 0 5px;" 
                    v-if="item.shipment_date_from && item.shipment_date_from !== '0000-00-00 00:00:00' && item.shipment_date_from !== '0000-00-00'"
                  >
                    ({{item.shipment_date_from | date}})
                  </span>
                </p>
              </div>
            </div>
            <div class="icon icon-arrow-right"></div>
          </a>

        </router-link>


        



      </ul>
      <router-link v-if="$root.add.can_add_order" to="/orders/new" class="add-plus"></router-link>
      <Pagination v-if="quantityList && quantityList > params.limit" @pagination="pagination" :dataList="{quantityList, params}"/>
    </div>
    <Filters @filter="filters" :filterChange="filterChange" :filter="filter"/>
  </div>
</template>









<script>
import Search from "@/components/app/Search"
import Filters from "@/components/app/Filters"
import Pagination from "@/components/app/Pagination"
import SelectedFilters from "@/components/app/SelectedFilters"


export default {
  data: () => ({
    //title
    globalTitle: [
      {title: 'Мої угоди', info: ''}
    ],
    is_mine: false,

    filter: {
      flag: true,
      mod: 'agreements'
    },
    btnFilter: false,
    agreements: null,
    agreementsNew: null,
    arrFilters: '',
    setinerval: '',

    //
    searchData: '',
    filtersData: '',
    filterChange: '',
    selectFilters: [],

    //pagination
    params: {
      start: 0, 
      limit: 20,
    },
    quantityList: '',
    listNot: false,
  }),




  async created() {
    //reload filters
    function getSearchParams(k){
      var p={};
      decodeURIComponent(document.location.search).replace(/[?&]+([^=&]+)=([^&]*)/gi,function(s,k,v){p[k]=v})
      return k?p[k]:p;
    }
    var efe = getSearchParams()
    if(efe['f[start]']) {
      this.params.start = efe['f[start]']
    }
    if(efe['f[search]']) {
      this.searchData = 'f%5Bsearch%5D=' + efe['f[search]']
    }
    this.filtersData = window.location.search
    this.arrFilters = window.location.search.slice(1)


    //title
    if(this.$root.app == 'SoftProCrmAppRioTransTest' || this.$root.app == 'SoftProCrmAppRioTrans'){
      this.globalTitle[0].title = this.$root.langText.menu.order;
    } else{
      this.globalTitle[0].title = this.$root.langText.menu.orderOther;
    }
    this.$root.title = this.globalTitle;



    //get agreements
    
    const res = await this.$store.dispatch('postFiltersOrders', {params: this.params, data: this.arrFilters})
    this.agreements = res.data;
    if(this.agreements){
      this.agreementsNew = res.data.list;
      this.quantityList = res.data.total_num
      if(this.quantityList == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }

      //notification
      localStorage.setItem('notificationOrder', false)
      var date = Math.round(new Date().getTime() / 1000)
      let app = this
      let arr = []
      this.setinerval = window.setInterval(async () => {
        app.agreementsNew.forEach(function(item, index){
          arr.push(item.id)
        })
        var data = {
          action: 'orders_list',
          ids: arr,
          date_from: date
        }
        var natification = await app.$store.dispatch('getNatification', data)
        if(natification.data.has_updates){
          const res = await app.$store.dispatch('postFiltersOrders', {params: app.params, data: app.arrFilters});
          app.agreements = res.data;
          app.agreementsNew = res.data.list;
          app.quantityList = res.data.total_num
        }
      }, 3000)
    }
  },




  methods: {

    //function filtration
    async reloadFilters(mod){
      if(mod !== 'pagination'){
        this.params.start = 0
      }
      this.arrFilters = this.searchData + "&" + this.filtersData + '&f%5Bstart%5D=' + this.params.start
      window.history.pushState('', '', window.location.origin + window.location.pathname + '?' + this.arrFilters)
      var arrFilters = await this.$store.dispatch('postFiltersOrders', {params: this.params, data: this.arrFilters})
      this.agreementsNew = arrFilters.data.list
      this.quantityList = arrFilters.data.total_num
      if(this.quantityList == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    },


    //filters
    async filters(data){
      this.filter.flag = true
      if(data == undefined){
        this.filtersData = '';
        this.btnFilter = false;
        this.selectFilters = [];
        this.reloadFilters('filters');
      } else{
        if(data.filter == ''){
          this.btnFilter = false
        } else{
          this.btnFilter = true
        }
        this.selectFilters = data.dataIsFilter
        this.filtersData = data.filter
        if(data.load !== true){
          this.reloadFilters('filters');
        }
      }
    },

    //search
    async search(data) {
      if(data !== undefined && data.load !== true){
        this.searchData = data.search
      }
      if(data.load !== true){
        this.reloadFilters('search');
      }
    },

    //paginations
    async pagination(data){
      this.params.start = data.start
      if(data.load !== true){
        this.reloadFilters('pagination')
      }
      window.scrollTo(0,0);
    }
  },

  beforeDestroy() {
    clearInterval(this.setinerval)
  },

  
  components: {
    Search, Filters, Pagination, SelectedFilters
  },
  metaInfo(){
    var title = this.$root.langText.directory.orderOther;
    if(this.$root.app == 'SoftProCrmAppRioTransTest' || this.$root.app == 'SoftProCrmAppRioTrans'){
      title = this.$root.langText.directory.order;
    }
    return {
      title: this.$title(title)
    }
  },
}

</script>